import React from 'react'
import NormalInput from '../components/form/NormalInput'
import HeaderText from '../components/text/HeaderText'
import MainBoldText from '../components/text/MainBoldText'
import PText from '../components/text/PText'
import MainButton from '../components/form/MainButton'
import PBlackText from '../components/text/PBlackText'

interface SignupPageProps {

}

const SignupPage: React.FC<SignupPageProps> = ({ }) => {
  return (
    <div className='container max-w-xl py-10'>
      <div className='pb-5 text-center'>
        <HeaderText text="Sign Up" />
      </div>
      <div className='text-center py-5'>
        <div className='pb-1'>
          <MainBoldText text="Complete your account" />
        </div>
        <div>
          <PText text="Lorem ipsum dolor sit amet" />
        </div>
      </div>

      <div className=' gap-5 grid grid-cols-1 px-1'>
        <NormalInput label="First Name" placeholder="Enter your First Name" />
        <NormalInput label="Last Name" placeholder="Enter your Last Name" />
        <NormalInput type='email' label="Your Email" placeholder="Enter your Email" />
        <NormalInput label="Phone Number" placeholder="Enter your Phone Number" />
        <NormalInput type='password' label="Password" placeholder="Enter your Password" />
        <NormalInput type='password' label="Confirm Password" placeholder="Confirm Password" />
        <MainButton text="Sign Up" />
      </div>

      <div className='text-center items-center justify-center flex py-5'>
        <PBlackText login={true} text="Already have an account?" />
      </div>
    </div>
  )
}

export default SignupPage