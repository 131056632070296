import React from 'react'

interface PTextProps {
  text: String;
}

const PText: React.FC<PTextProps> = ({ text }) => {
  return (
    <div className='text-[14px] font-[600] text-textGray'>
      {text}
    </div>
  )
}

export default PText