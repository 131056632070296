import React from 'react'
import { IoMenu } from 'react-icons/io5'

interface TopMenuProps {
    type: 'gray' | 'white', 
}

const TopMenu: React.FC<TopMenuProps> = ({ type }) => {
  return (
    <div className={`cursor-pointer md:text-textGray md:p-3 md:rounded-full hidden md:flex ${type === 'white' ? 'md:bg-white' : 'md:bg-darkGray'}`}>
      <IoMenu size={24} />
    </div>
  )
}

export default TopMenu