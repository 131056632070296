import React from 'react'
import { Outlet } from "react-router-dom";
import Logo from '../../images/logo1923.png';

const DashLayout: React.FC = () => {
  return (
    <>
      <div className=" bg-background h-screen">
        <div className='overflow-auto'>
          <Outlet />
        </div>
      </div>
    </>
  )
}

export default DashLayout