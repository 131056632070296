import React from 'react'

interface ServiceTextProps {
  text: string;
}

const ServiceText: React.FC<ServiceTextProps> = ({ text }) => {
  return (
    <div className='flex'>
    <div className=' bg-lightBlue px-3 py-2 rounded-lg text-[12px] text-blue font-[600]'>
      {text}
    </div>
  </div>
  )
}

export default ServiceText