import React from 'react'

interface MainBoldTextProps {
  text: String;
}

const MainBoldText: React.FC<MainBoldTextProps> = ({ text }) => {
  return (
    <div className='text-[22px] font-[700] tracking-tight'>
      {text}
    </div>
  )
}

export default MainBoldText