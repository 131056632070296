import React from 'react'

interface ServiceTextSmallProps {
  text: string
}

const ServiceTextSmall: React.FC<ServiceTextSmallProps> = ({ text }) => {
  return (
    <div className='flex'>
      <div className=' bg-lightBlue px-2 py-1 rounded-lg text-[10px] text-blue font-[600]'>
        {text}
      </div>
    </div>
  )
}

export default ServiceTextSmall