import React from 'react'
import ServiceText from '../components/text/ServiceText'
import NormalText from '../components/text/NormalText'
import MainBoldText from '../components/text/MainBoldText'
import HeaderText from '../components/text/HeaderText'
import { FaStar } from "react-icons/fa";
import PBlackText from '../components/text/PBlackText'
import PText from '../components/text/PText'
import { IoLocationSharp } from 'react-icons/io5'
import PSmallGrayText from '../components/text/PSmallGrayText'
import LinkText from '../components/text/LinkText'
import ReadMore from '../components/text/ReadMore'
import DescriptionText from '../components/text/DescriptionText'
import MainButton from '../components/form/MainButton'
import { CiHeart } from "react-icons/ci";

interface SolvePageProps {

}

const SolvePage: React.FC<SolvePageProps> = ({ }) => {
  return (
    <div className=' bg-background'>
      <div className=' w-full'>
        <div className=' absolute right-3 top-3 text-red-600'>
          <CiHeart size={24} />
        </div>
        <img src="ac.jpg" alt="ac" className=' w-full' />

      </div>
      <div className='container pt-6'>
        <div className='flex justify-between items-center'>
          <ServiceText text='Barber Service' />
          {/* <div className='flex items-center'>
            <img src="head.png" alt="ac" className=' w-[40px] max-w-[40px] min-w-[40px] object-contain rounded-full' />
            <div className='pt-1'>
              <PSmallGrayText text='Koby' />
            </div>
          </div> */}
        </div>


        <div className='py-3'>
          <HeaderText text='Jeanette barbershop professional haircut' />
        </div>

        <div className='flex items-center'>
          <IoLocationSharp className='mr-1 text-textGray' />
          <PText text='ABC Avenue Dhaka' />
          <div className='mx-2'>
            <div className='w-1 h-1 bg-textGray rounded-full '></div>
          </div>
          <FaStar color='#facc15' size={16} />
          <div className='text-[12px] ml-1 text-yellow-400 font-semibold'>4.4</div>
          <div className='text-[12px] ml-1 text-textGray'>(532)</div>
        </div>

        <div className='pt-6'>
          <HeaderText text={"Description"} />
        </div>
        <DescriptionText text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas delectus maxime sapiente! Incidunt, modi natus, veritatis minima esse atque enim qui ipsum..." />
        <div className='py-3'>
          <MainButton text='Select for ($100)' />

        </div>
      </div>

    </div>
  )
}

export default SolvePage