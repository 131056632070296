import React from 'react'
import SmallLightText from '../text/SmallLightText'
import NormalText from '../text/NormalText'
import { IoLocationSharp } from 'react-icons/io5'
import PSmallGrayText from '../text/PSmallGrayText'

interface HomePageCardProps {

}

const HomePageCard: React.FC<HomePageCardProps> = ({ }) => {
  return (
    <div className='bg-white cursor-pointer max-w-[240px] mb-3 w-[230px] rounded-lg p-2 shadow-md'>
      <div>
        <img src="ac.jpg" alt="ac" className=' h-28 max-h-28 w-full object-cover rounded-lg' />
        <div className='pt-2'>
          <SmallLightText text="Home Service" />
        </div>
        <div className='py-2'>
          <NormalText text='AC service of various types and repairs' />

          <div className='pt-3 flex justify-between items-center'>
            <div className='flex items-center text-textGray'>
              <IoLocationSharp className='mr-1' />
              <PSmallGrayText text={"California CA"} />
            </div>

            <div className=' bg-lightGreen px-3 py-1 rounded-lg text-blue font-[500]'>
              $23.50
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default HomePageCard