import React from 'react'

interface NormalInputProps {
  label?: string;
  placeholder: string;
  type?: "text" | "email" | "password";
}

const NormalInput: React.FC<NormalInputProps> = ({ placeholder, label, type }) => {

  const inputType = type || "text";

  return (
    <div>
      <div className='pb-2 text-[14px] text-textGray font-[500]'>{label}</div>
      <input type={inputType} placeholder={placeholder} className='w-full font-[500] py-3 px-4 rounded-full focus:outline-none bg-inputBg' />
    </div>
  )
}

export default NormalInput