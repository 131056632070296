import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DashLayout from './components/layout/DashLayout';
import SignupPage from './pages/SignupPage';
import EmployerPage from './pages/EmployerPage';
import SearchPage from './pages/SearchPage';
import SolvePage from './pages/SolvePage';
import ContactPage from './pages/ContactPage';
import ConfirmPage from './pages/ConfirmPage';
import BookingPage from './pages/BookingPage';
import BookingDetailPage from './pages/BookingDetailPage';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<DashLayout />}>
          <Route index element={<EmployerPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/solve" element={<SolvePage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/confirm" element={<ConfirmPage />} />
          <Route path="/booking" element={<BookingPage />} />
          <Route path="/bookingdetail" element={<BookingDetailPage />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
