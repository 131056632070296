import React from 'react'

interface SmallLightTextProps {
  text: string;
}

const SmallLightText: React.FC<SmallLightTextProps> = ({ text }) => {
  return (
    <div className='text-[8px] text-textGray tracking-wider'>
      {text}
    </div>
  )
}

export default SmallLightText