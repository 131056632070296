import React from 'react'
import NormalText from '../text/NormalText'
import PSmallGrayText from '../text/PSmallGrayText'
import LinkText from '../text/LinkText'

interface ProfessionalPeopleCardProps {

}

const ProfessionalPeopleCard: React.FC<ProfessionalPeopleCardProps> = ({ }) => {
  return (
    <div className='flex justify-between items-center pt-4'>
      <div className='flex items-center'>
        <img src="head.png" alt="ac" className=' w-16 h-16 max-w-16 min-w-16 min-h-16 max-h-16 rounded-lg' />
        <div className='ml-3'>
          <div><NormalText text='Matt Dibbert' /></div>
          <div>
            <PSmallGrayText text={"Available"} />
          </div>
        </div>

      </div>
      <div>
        <LinkText text='See Detail' />
      </div>
    </div>
  )
}

export default ProfessionalPeopleCard