import React from 'react'
import { IoIosNotificationsOutline } from "react-icons/io";

interface NotificationProps {

}

const Notification: React.FC<NotificationProps> = ({ }) => {
  return (
    <div className='relative'>
      <div className=' absolute bg-red-600 w-2 h-2 right-1 top-1 rounded-full' />
      <IoIosNotificationsOutline size={28} />
    </div>
  )
}

export default Notification