import React from 'react'

interface MainButtonProps {
  text: string;

}

const MainButton: React.FC<MainButtonProps> = ({ text }) => {
  return (
    <div className='w-full cursor-pointer bg-blue text-white text-center py-3 text-[16px] font-[500] tracking-wider rounded-full'>
      {text}
    </div>
  )
}

export default MainButton