import React from 'react'
import { IoLocationSharp } from "react-icons/io5";
import { IoHomeOutline } from "react-icons/io5";
import { CiCalendar } from "react-icons/ci";
import { CiHeart } from "react-icons/ci";
import { MdOutlineManageAccounts } from "react-icons/md";
import SmallLightText from '../text/SmallLightText';
interface FooterProps {

}

const Footer: React.FC<FooterProps> = ({ }) => {
  return (
    <div className='fixed w-full bg-white py-3 px-10 bottom-0 flex md:hidden justify-between' style={{ zIndex: '1000' }}>
      <div className='flex flex-col items-center'>
        <IoHomeOutline className=' text-textGray' size={24} />
        <div className='pt-1'>
          <SmallLightText text='Home' />

        </div>
      </div>
      <div className='flex flex-col items-center'>
        <CiCalendar className=' text-textGray' size={24} />
        <div className='pt-1'>
          <SmallLightText text='My Booking' />

        </div>
      </div>
      <div className='flex flex-col items-center'>
        <CiHeart className=' text-textGray' size={24} />
        <div className='pt-1'>
          <SmallLightText text='Favorite' />

        </div>
      </div>
      <div className='flex flex-col items-center'>
        <MdOutlineManageAccounts className=' text-textGray' size={24} />
        <div className='pt-1'>
          <SmallLightText text='Home' />

        </div>
      </div>
    </div>
  )
}

export default Footer