import React from 'react'

interface PBlackTextProps {
  text: string;
  login?: boolean;
}

const PBlackText: React.FC<PBlackTextProps> = ({ text, login }) => {
  return (
    <div className={`text-black font-[500] ${login && 'flex'}`}>
      {text}
      {
        login && (
          <div className='text-blue ml-3 cursor-pointer font-[600]'>
            Login
          </div>
        )
      }
    </div>
  )
}

export default PBlackText