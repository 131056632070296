import React from 'react'
import HeaderText from '../components/text/HeaderText'
import ServiceText from '../components/text/ServiceText'
import MainBoldText from '../components/text/MainBoldText'
import { IoLocationSharp } from 'react-icons/io5'
import PText from '../components/text/PText'
import { FaStar } from 'react-icons/fa'

interface BookingDetailPageProps {

}

const BookingDetailPage: React.FC<BookingDetailPageProps> = ({ }) => {
  return (
    <div className='container py-5'>
      <div className='text-center py-3'>
        <MainBoldText text={"Booking Detail"} />
      </div>

      <div>
        <img src="ac.jpg" alt="ac" className=' w-full h-56 max-h-56 object-cover rounded-xl' />
        <div className='py-3'>
          <ServiceText text='Barber Service' />
        </div>
        <div>
          <HeaderText text={"Lorem ipsum, dolor sit amet consectetur adipisicing elit."} />
        </div>
        <div className='py-3'>
          <div className='flex items-center'>
            <IoLocationSharp className='mr-1 text-textGray' />
            <PText text='ABC Avenue Dhaka' />
            <div className='mx-2'>
              <div className='w-1 h-1 bg-textGray rounded-full '></div>
            </div>
            <FaStar color='#facc15' size={16} />
            <div className='text-[12px] ml-1 text-yellow-400 font-semibold'>4.4</div>
            <div className='text-[12px] ml-1 text-textGray'>(532)</div>
          </div>
        </div>

        {/* <div className='pt-3'>
          <div className='flex items-center'>
            <div className=' border-b border-blue text-blue flex-1 text-center'>Service Todo</div>
            <div className=' border-b border-darkGray text-textGray flex-1 text-center'>Service Todo</div>
          </div>
        </div> */}

      </div>
    </div>
  )
}

export default BookingDetailPage