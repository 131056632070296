import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
interface ModalLayoutProps {
  children: React.ReactNode;
  openModal: boolean;
  setOpenModal: (open: boolean) => void;
  light?: boolean;
}

const ModalLayout: React.FC<ModalLayoutProps> = ({ children, openModal, setOpenModal, light }) => {
  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={openModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpenModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={`${!light && "fixed inset-0 bg-gray-500 bg-opacity-60 transition-opacity"}`} />

        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto ">
          <div className="flex min-h-full items-end justify-center sm:items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 -translate-y-full" // Start from the bottom
              enterTo="opacity-100 translate-y-0" // End at center
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0" // Start from center
              leaveTo="opacity-0 translate-y-full" // Exit to the bottom
            >
              <Dialog.Panel className="relative pb-10 transform overflow-hidden w-full rounded-t-3xl bg-white shadow-custom-2">
                <div className='py-3 w-full flex items-center justify-center'>
                  <div className='w-10 h-[6px] rounded-xl bg-darkGray' />
                </div>

                {children}

              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default ModalLayout