import React from 'react'

interface PSmallGrayTextProps {
  text: string;
}

const PSmallGrayText: React.FC<PSmallGrayTextProps> = ({ text }) => {
  return (
    <div className=' text-textGray text-[12px] tracking-tight'>
      {text}
    </div>
  )
}

export default PSmallGrayText