import React from 'react'

interface LinkTextProps {
  text: string;
}

const LinkText: React.FC<LinkTextProps> = ({ text }) => {
  return (
  <div className='text-blue md:text-[14px] font-normal cursor-pointer hover:underline'>
    {text}
  </div>
  )
}

export default LinkText