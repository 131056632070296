import React from 'react'

interface NormalTextProps {
  text: string
}

const NormalText: React.FC<NormalTextProps> = ({ text }) => {
  return (
  <div className='text-[14px] md:text-[16px] font-[600] tracking-tight'>
    {text}
  </div>
  )
}

export default NormalText