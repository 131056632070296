import React from 'react'

interface StatusViewProps {
  type: 'going' | 'completed' | 'canceled'
}

const StatusView: React.FC<StatusViewProps> = ({ type }) => {
  return (
    <div className={`border-2 rounded-lg font-[600] px-3 text-[12px] py-1 ${type === 'going' && 'border-blue text-blue'} ${type === 'canceled' && 'border-red-500 text-red-500'} ${type === 'completed' && 'border-green-500 text-green-500'}`}>
      {
        type === 'going' && (
          <div className=''>
            On Going

          </div>

        )
      }
      {type === 'canceled' && (
        <div className=''>
          Canceled
        </div>
      )}
      {type === 'completed' && (
        <div className=''>
          Completed
        </div>
      )}
    </div>
  )
}

export default StatusView