import React, { useState } from 'react'
import { IoIosArrowBack } from "react-icons/io";
import SearchInput from '../components/form/SearchInput';
import HeaderText from '../components/text/HeaderText';
import Notification from '../components/ui/Notification';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { FreeMode } from 'swiper/modules';
import HomePageCategory from '../components/ui/HomePageCategory';
import NormalText from '../components/text/NormalText';
import SearchPageCard from '../components/ui/SearchPageCard';
import ModalLayout from '../components/layout/ModalLayout';
import MainBoldText from '../components/text/MainBoldText';
import LocationInput from '../components/form/LocationInput';
import MainButton from '../components/form/MainButton';
import Footer from '../components/ui/Footer';
import TopMenu from '../components/ui/TopMenu';
interface SearchPageProps {

}

const SearchPage: React.FC<SearchPageProps> = ({ }) => {

  const [search, setSearch] = useState("Ac service");
  const [filter, setFilter] = useState<boolean>(true);

  const [openModal, setOpenModal] = useState<boolean>(true);
  return (
    <div className=' bg-background '>
      <div className='pb-14'>
        <div className={`container ${openModal && 'blur-sm'}`}>
          <div className='flex justify-between items-center py-5 md:py-10'>
            <div className=' bg-darkGray flex justify-center items-center w-10 h-10 rounded-full'>
              <IoIosArrowBack size={20} />
            </div>
            <div className='hidden md:flex'>
              <SearchInput bg='gray' />
            </div>
            <div className='md:hidden'>
              <HeaderText text='Search' />
            </div>
            <div className=' md:hidden'>
              <Notification />
            </div>
            <div className='hidden md:flex'>
              <TopMenu type="gray" />
            </div>
          </div>
          <div className='md:hidden'>
            <SearchInput bg='gray' />
          </div>
          <div className='pt-5 md:hidden'>
            <div className='flex justify-between items-center pt-1 pb-3'>
              <NormalText text={"Categories"} />
            </div>
            <Swiper
              spaceBetween={10}
              freeMode={true}
              slidesPerView={'auto'}
              modules={[FreeMode]}
            >
              <SwiperSlide style={{ width: 'auto' }}>
                <HomePageCategory active={true} />
              </SwiperSlide>
              <SwiperSlide style={{ width: 'auto' }}>
                <HomePageCategory active={false} />
              </SwiperSlide>
              <SwiperSlide style={{ width: 'auto' }}>
                <HomePageCategory active={false} />
              </SwiperSlide>
              <SwiperSlide style={{ width: 'auto' }}>
                <HomePageCategory active={false} />
              </SwiperSlide>
              <SwiperSlide style={{ width: 'auto' }}>
                <HomePageCategory active={false} />
              </SwiperSlide>
              <SwiperSlide style={{ width: 'auto' }}>
                <HomePageCategory active={false} />
              </SwiperSlide>
              <SwiperSlide style={{ width: 'auto' }}>
                <HomePageCategory active={false} />
              </SwiperSlide>
              <SwiperSlide style={{ width: 'auto' }}>
                <HomePageCategory active={false} />
              </SwiperSlide>
              <SwiperSlide style={{ width: 'auto' }}>
                <HomePageCategory active={false} />
              </SwiperSlide>
            </Swiper>
          </div>



          <div className='pt-5 pb-3 md:grid-cols-12 md:grid '>
            <div className='hidden md:grid md:col-span-2'>
              <div>
                <div className='py-3'><NormalText text={"Categories"} /></div>
                <div className='md:flex-wrap md:flex md:justify-center md:items-center gap-3'>

                  <div className=''>
                    <HomePageCategory active={true} />
                  </div>
                  <div className=''>
                    <HomePageCategory active={false} />
                  </div>
                  <div className=''>
                    <HomePageCategory active={false} />
                  </div>
                  <div className=''>
                    <HomePageCategory active={false} />
                  </div>
                  <div className=''>
                    <HomePageCategory active={false} />
                  </div>

                </div>
              </div>
            </div>
            <div className=' md:grid md:col-span-10'>
              <NormalText text='Service found (34)' />
              <div className='py-3'>
                <div className=' grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-3 lg:gap-5'>
                  <SearchPageCard />
                  <SearchPageCard />
                  <SearchPageCard />
                  <SearchPageCard />
                  <SearchPageCard />
                  <SearchPageCard />
                  <SearchPageCard />
                  <SearchPageCard />
                  <SearchPageCard />
                  <SearchPageCard />
                  <SearchPageCard />
                  <SearchPageCard />
                </div>
              </div>

            </div>
          </div>

        </div>
        <ModalLayout openModal={openModal} setOpenModal={setOpenModal}>
          <div className='container'>
            <div className='pt-1 text-center'>
              <HeaderText text={"Filter Service"} />
            </div>


            <div className='pt-6 pb-10'>
              <NormalText text='Locations' />
              <div className='py-3'>
                <LocationInput />
              </div>
              <div className='pt-3'>
                <NormalText text='Categories' />

                <div className='py-3'>
                  <Swiper
                    spaceBetween={10}
                    slidesPerView={3}
                  >
                    <SwiperSlide>
                      <HomePageCategory active={true} />
                    </SwiperSlide>
                    <SwiperSlide>
                      <HomePageCategory active={false} />
                    </SwiperSlide>
                    <SwiperSlide>
                      <HomePageCategory active={false} />
                    </SwiperSlide>
                    <SwiperSlide>
                      <HomePageCategory active={false} />
                    </SwiperSlide>
                    <SwiperSlide>
                      <HomePageCategory active={false} />
                    </SwiperSlide>
                    <SwiperSlide>
                      <HomePageCategory active={false} />
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>

              <div className='pt-6'>
                <MainButton text='Apply Filter' />
              </div>
            </div>
          </div>
        </ModalLayout>

      </div>


      <Footer />
    </div>
  )
}

export default SearchPage