import React from 'react'

interface ReadMoreProps {
    text: string
}

const ReadMore: React.FC<ReadMoreProps> = ({ text }) => {
  return (
  <span className=' text-blue ml-3 tracking-normal text-[12px] font-semibold'>
    {text}
  </span>
  )
}

export default ReadMore