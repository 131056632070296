import React, { useState } from 'react'
import HeaderText from '../components/text/HeaderText'
import Notification from '../components/ui/Notification'
import { IoIosArrowBack } from "react-icons/io";
import { CiHeart } from "react-icons/ci";
import NormalText from '../components/text/NormalText';
import PText from '../components/text/PText';
import ServiceText from '../components/text/ServiceText';
import ServiceTextSmall from '../components/text/ServiceTextSmall';
import MainBoldText from '../components/text/MainBoldText';
import { IoLocationSharp } from 'react-icons/io5';
import { FaStar } from 'react-icons/fa';

import { IoCallOutline } from "react-icons/io5";
import PSmallGrayText from '../components/text/PSmallGrayText';
import { CiMap } from "react-icons/ci";
import { LuMessageSquare } from "react-icons/lu";
import { IoShareSocialOutline } from "react-icons/io5";
import ModalLayout from '../components/layout/ModalLayout';
import PBlackText from '../components/text/PBlackText';
import MainButton from '../components/form/MainButton';
import LinkText from '../components/text/LinkText';
import { FaRegCopy } from "react-icons/fa6";
import ProfessionalPeopleCard from '../components/ui/ProfessionalPeopleCard';
interface ContactPageProps {

}

const ContactPage: React.FC<ContactPageProps> = ({ }) => {
  const [openModal, setOpenModal] = useState<boolean>(true);
  const [openShareModal, setOpenShareModal] = useState<boolean>(false);
  return (
    <React.Fragment>

      <div className={`flex flex-col h-screen ${openShareModal && 'blur-sm'}`}>
        <div className='container'>

          <div className='flex justify-between items-center py-5'>
            <div className=' bg-darkGray flex justify-center items-center w-10 h-10 rounded-full'>
              <IoIosArrowBack size={20} />
            </div>
            <div className='flex justify-center flex-col items-center'>
              <img src="head.png" alt="ac" className=' w-10 h-10 max-w-10 min-w-10 min-h-10 max-h-10 rounded-full' />
              <div className=''>
                <PText text='Kim Jerde' />
              </div>
            </div>
            <CiHeart size={32} />
          </div>

          <div className=''>
            <ServiceTextSmall text='Barber Service' />
          </div>

          <div className='py-2'>
            <HeaderText text={"Lorem ipsum dolor sit amet consectetur adipisicing elit."} />
          </div>

          <div className='flex items-center'>
            <IoLocationSharp className='mr-1 text-textGray' />
            <PText text='ABC Avenue Dhaka' />
            <div className='mx-2'>
              <div className='w-1 h-1 bg-textGray rounded-full '></div>
            </div>
            <FaStar color='#facc15' size={16} />
            <div className='text-[12px] ml-1 text-yellow-400 font-semibold'>4.4</div>
            <div className='text-[12px] ml-1 text-textGray'>(532)</div>
          </div>



          <div className='flex justify-between items-center pt-5 pb-4'>
            <div className='flex justify-center flex-col items-center'>
              <IoCallOutline size={24} />
              <div className='pt-1'>
                <PSmallGrayText text={"Call"} />
              </div>
            </div>
            <div className='flex justify-center flex-col items-center'>
              <LuMessageSquare size={24} />
              <div className='pt-1'>
                <PSmallGrayText text={"Chat"} />
              </div>
            </div>
            <div className='flex justify-center flex-col items-center'>
              <CiMap size={24} />
              <div className='pt-1'>
                <PSmallGrayText text={"Location"} />
              </div>
            </div>
            <div onClick={() => setOpenShareModal(true)} className='flex justify-center flex-col items-center'>
              <IoShareSocialOutline size={24} />
              <div className='pt-1'>
                <PSmallGrayText text={"Share"} />
              </div>
            </div>
          </div>
          {
            !openModal && (
              <div onClick={() => setOpenModal(true)} className='pb-4'>
                <MainButton text='Select' />
              </div>
            )
          }
        </div>
        <div className='w-full h-1 bg-gray-200'></div>

        <div className=" overflow-y-auto h-[calc(100vh-15rem)]">

          <div className='container pt-5'>
            <HeaderText text={"Professional (8)"} />

            <ProfessionalPeopleCard />
            <ProfessionalPeopleCard />
            <ProfessionalPeopleCard />
            <ProfessionalPeopleCard />
            <ProfessionalPeopleCard />
            <ProfessionalPeopleCard />

          </div>
        </div>
      </div>

      <ModalLayout light={true} openModal={openModal} setOpenModal={setOpenModal} >
        <div className='container'>
          <div className='flex justify-between'>
            <div>
              <PText text={"Service"} />
            </div>
            <div>
              <NormalText text='Service name' />
            </div>
          </div>
          <div className='pt-6'>
            <MainButton text='Checkout ($100)' />

          </div>
        </div>
      </ModalLayout>

      <ModalLayout openModal={openShareModal} setOpenModal={setOpenShareModal} >

        <div className='container'>
          <div>
            <HeaderText text='Share this Service' />
          </div>
          <div className='py-5'>
            <div className='w-full h-[1px] bg-darkGray' />
          </div>

          <div className='flex items-center'>
            <img src="ac.jpg" alt="ac" className=' w-20 h-20 max-w-20 min-w-20 min-h-20 max-h-20 rounded-lg object-cover' />
            <div className='ml-3'>
              <NormalText text='Lorem ipsum dolor sit, amet consectetur adipisicing elit.' />

              <div className='flex items-center py-2'>
                <IoLocationSharp className='mr-1 text-textGray' />
                <PText text='ABC Avenue Dhaka' />
                <div className='mx-2'>
                  <div className='w-1 h-1 bg-textGray rounded-full '></div>
                </div>
                <FaStar color='#facc15' size={16} />
                <div className='text-[12px] ml-1 text-yellow-400 font-semibold'>4.4</div>
                <div className='text-[12px] ml-1 text-textGray'>(532)</div>
              </div>


            </div>
          </div>
          <div className='pt-5'>
            <div className=' bg-inputBg w-full flex justify-between items-center py-2 px-3 rounded-lg'>
              <input value={'daersd fsdf sd fwerf sdfsd'} className=' bg-inputBg text-textGray text-[14px]' type="text" />
              <div className='bg-white px-3 py-[3px] text-blue text-[12px] rounded-md flex items-center font-[600]'>
                <FaRegCopy className='mr-1' />
                Copy
              </div>
            </div>
          </div>

        </div>
      </ModalLayout>

    </React.Fragment>
  )
}

export default ContactPage