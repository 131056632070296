import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet/dist/leaflet.css';
import SearchInput from '../components/form/SearchInput';

import NormalText from '../components/text/NormalText';
import LinkText from '../components/text/LinkText';

import { FreeMode } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import HomePageCard from '../components/ui/HomePageCard';
import Footer from '../components/ui/Footer';
import HomePageCategory from '../components/ui/HomePageCategory';
import { IoMenu } from "react-icons/io5";
import TopMenu from '../components/ui/TopMenu';
interface EmployerPageProps {

}

interface Location {
  lat: number;
  lng: number;
}

const EmployerPage: React.FC<EmployerPageProps> = ({ }) => {
  const [location, setLocation] = useState<Location>({ lat: 0, lng: 0 });

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        setLocation({ lat: latitude, lng: longitude });
      }, (error) => {
        console.error("Error Code = " + error.code + " - " + error.message);
      });
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);


  return (
    <div className='md:container'>

      <div className='w-full h-screen relative'>
        <MapContainer center={[52.4797, -1.90269]} zoom={15} dragging={false} doubleClickZoom={false} zoomControl={false} scrollWheelZoom={false} className='w-full h-full'>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={[51.5074, -0.1278]}>
            <Popup>
              <div className="">
                <div className="">
                  <span> bedroom</span>
                  <b>$ 456456</b>
                </div>
              </div>
            </Popup>
          </Marker>
          <div className=' py-5 absolute w-full mt-14' style={{ zIndex: '1000' }}>
            <div className='flex justify-center md:justify-between items-center px-6'>
              <div></div>
              <div className=''>
                <SearchInput bg='white' />
              </div>
              <TopMenu type='white' />
            </div>
            <div className=' bg-gray-50 w-full mt-8 h-screen overflow-auto pb-56 rounded-t-3xl shadow-custom-2'>

              <div className='absolute z-10 bg-white  py-3 w-full flex items-center rounded-t-3xl justify-center'>
                <div className='w-10 h-[6px] rounded-xl bg-darkGray' />
              </div>

              <div className='container mt-10'>
                <div className=' md:grid md:grid-cols-12'>

                  <div className='hidden md:grid md:col-span-3 md:mr-2'>
                    <div>
                      <div className='py-3'><NormalText text={"Categories"} /></div>
                      <div className='md:flex-wrap md:flex md:justify-center md:items-center gap-3'>

                        <div className=''>
                          <HomePageCategory active={true} />
                        </div>
                        <div className=''>
                          <HomePageCategory active={false} />
                        </div>
                        <div className=''>
                          <HomePageCategory active={false} />
                        </div>
                        <div className=''>
                          <HomePageCategory active={false} />
                        </div>
                        <div className=''>
                          <HomePageCategory active={false} />
                        </div>

                      </div>
                    </div>
                  </div>

                  <div className='md:col-span-9'>
                    <div className='md:hidden'>
                      <div className='flex justify-between items-center pt-1 pb-3'>
                        <NormalText text={"Categories"} />
                      </div>
                      <Swiper
                        spaceBetween={10}
                        freeMode={true}
                        slidesPerView={'auto'}
                        modules={[FreeMode]}
                      >
                        <SwiperSlide style={{ width: 'auto' }}>
                          <HomePageCategory active={true} />
                        </SwiperSlide>
                        <SwiperSlide style={{ width: 'auto' }}>
                          <HomePageCategory active={false} />
                        </SwiperSlide>
                        <SwiperSlide style={{ width: 'auto' }}>
                          <HomePageCategory active={false} />
                        </SwiperSlide>
                        <SwiperSlide style={{ width: 'auto' }}>
                          <HomePageCategory active={false} />
                        </SwiperSlide>
                        <SwiperSlide style={{ width: 'auto' }}>
                          <HomePageCategory active={false} />
                        </SwiperSlide>
                        <SwiperSlide style={{ width: 'auto' }}>
                          <HomePageCategory active={false} />
                        </SwiperSlide>
                        <SwiperSlide style={{ width: 'auto' }}>
                          <HomePageCategory active={false} />
                        </SwiperSlide>
                        <SwiperSlide style={{ width: 'auto' }}>
                          <HomePageCategory active={false} />
                        </SwiperSlide>
                        <SwiperSlide style={{ width: 'auto' }}>
                          <HomePageCategory active={false} />
                        </SwiperSlide>
                      </Swiper>
                    </div>
                    <div className=''>
                      <div className='flex justify-between items-center pt-6 md:pt-3 pb-3 pr-3'>
                        <NormalText text={"Home Service"} />
                        <LinkText text="See All" />
                      </div>
                      <Swiper
                        spaceBetween={10}
                        freeMode={true}
                        slidesPerView={'auto'}
                        modules={[FreeMode]}
                      >
                        <SwiperSlide style={{ width: 'auto' }}>
                          <HomePageCard />

                        </SwiperSlide>
                        <SwiperSlide style={{ width: 'auto' }}>
                          <HomePageCard />
                        </SwiperSlide>
                        <SwiperSlide style={{ width: 'auto' }}>
                          <HomePageCard />
                        </SwiperSlide>
                        <SwiperSlide style={{ width: 'auto' }}>
                          <HomePageCard />
                        </SwiperSlide>
                        <SwiperSlide style={{ width: 'auto' }}>
                          <HomePageCard />
                        </SwiperSlide>
                        <SwiperSlide style={{ width: 'auto' }}>
                          <HomePageCard />
                        </SwiperSlide>
                      </Swiper>
                    </div>
                    <div className=''>
                      <div className='flex justify-between items-center pt-6 pb-3 pr-3'>
                        <NormalText text={"Home Service"} />
                        <LinkText text="See All" />
                      </div>
                      <Swiper
                        spaceBetween={10}
                        freeMode={true}
                        slidesPerView={'auto'}
                        modules={[FreeMode]}
                      >
                        <SwiperSlide style={{ width: 'auto' }}>
                          <HomePageCard />

                        </SwiperSlide>
                        <SwiperSlide style={{ width: 'auto' }}>
                          <HomePageCard />
                        </SwiperSlide>
                        <SwiperSlide style={{ width: 'auto' }}>
                          <HomePageCard />
                        </SwiperSlide>
                        <SwiperSlide style={{ width: 'auto' }}>
                          <HomePageCard />
                        </SwiperSlide>
                        <SwiperSlide style={{ width: 'auto' }}>
                          <HomePageCard />
                        </SwiperSlide>
                        <SwiperSlide style={{ width: 'auto' }}>
                          <HomePageCard />
                        </SwiperSlide>
                      </Swiper>
                    </div>
                    <div className=''>
                      <div className='flex justify-between items-center pt-6 pb-3 pr-3'>
                        <NormalText text={"Home Service"} />
                        <LinkText text="See All" />
                      </div>
                      <Swiper
                        spaceBetween={10}
                        freeMode={true}
                        slidesPerView={'auto'}
                        modules={[FreeMode]}
                      >
                        <SwiperSlide style={{ width: 'auto' }}>
                          <HomePageCard />

                        </SwiperSlide>
                        <SwiperSlide style={{ width: 'auto' }}>
                          <HomePageCard />
                        </SwiperSlide>
                        <SwiperSlide style={{ width: 'auto' }}>
                          <HomePageCard />
                        </SwiperSlide>
                        <SwiperSlide style={{ width: 'auto' }}>
                          <HomePageCard />
                        </SwiperSlide>
                        <SwiperSlide style={{ width: 'auto' }}>
                          <HomePageCard />
                        </SwiperSlide>
                        <SwiperSlide style={{ width: 'auto' }}>
                          <HomePageCard />
                        </SwiperSlide>
                      </Swiper>
                    </div>
                  </div>

                </div>





              </div>



            </div>

          </div>



          <Footer />




        </MapContainer>
      </div>

    </div>
  )
}

export default EmployerPage