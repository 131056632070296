import React from 'react'
import { CiSearch, CiFilter } from "react-icons/ci";
interface SearchInputProps {
  bg: "gray" | "white"
}

const SearchInput: React.FC<SearchInputProps> = ({ bg }) => {
  return (
    <div className={`w-full min-w-[350px] text-gray-600 rounded-full py-3 px-3 flex items-center justify-between ${bg === 'gray' ? 'bg-inputBg' : 'bg-white'}`} >
      <div className='flex items-center'>
        <div>
          <CiSearch className='text-gray-600' size={18} />
        </div>
        <div className='ml-2'>
          <input placeholder='Search...' className={`text-gray-600 outline-none text-[14px] ${bg === 'gray' ? 'bg-inputBg' : 'bg-white'}`} />
        </div>

      </div>
      <div className=' border-l-2 text-gray-600'>
        <CiFilter className='text-gray-600 cursor-pointer' size={18} />
      </div>
    </div>
  )
}

export default SearchInput