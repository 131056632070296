import React from 'react'
import PSmallGrayText from './PSmallGrayText'
import ReadMore from './ReadMore'

interface DescriptionTextProps {
  text: string
}

const DescriptionText: React.FC<DescriptionTextProps> = ({ text }) => {
  return (
    <div className='pt-3 text-justify tracking-wider leading-loose'>
      <div className=' text-textGray text-[12px] tracking-tight'>
        {text}
        <ReadMore text='Read More' />
      </div>
    </div>
  )
}

export default DescriptionText