import React from 'react'
import { CiLocationOn } from "react-icons/ci";
interface LocationInputProps {

}

const LocationInput: React.FC<LocationInputProps> = ({ }) => {
  return (
    <div className='border w-full rounded-xl px-4 py-3 flex items-center'>
      <input type="text" className=' w-full outline-none' placeholder='New York, US' />
      <div className='text-blue'>
        <CiLocationOn size={20} />
      </div>
    </div>
  )
}

export default LocationInput