import React from 'react'
import { CiHeart } from "react-icons/ci";
import { FaStar } from "react-icons/fa";
import NormalText from '../text/NormalText';
import PSmallGrayText from '../text/PSmallGrayText';
interface SearchPageCardProps {

}

const SearchPageCard: React.FC<SearchPageCardProps> = ({ }) => {
  return (
    <div className='bg-white px-2 py-2 rounded-lg shadow-md'>
      <div className=' relative'>
        <div className=' absolute right-1 top-1 text-red-600'>
          <CiHeart size={24} />
        </div>
        <img src="ac.jpg" alt="ac" className=' h-28 max-h-28 w-full object-cover rounded-lg' />
      </div>

      <div className='py-2 flex items-center'>
        <FaStar color='#facc15' size={16} />
        <div className='text-[12px] ml-1 text-yellow-400 font-semibold'>4.4</div>
        <div className='text-[12px] ml-1 text-textGray'>(532)</div>
      </div>

      <div>
        <NormalText text="Shoe repair sercive" />
        <PSmallGrayText text='Chestnut Street Rome, NY' />
        <div className='py-1'>
          <NormalText text='$19.00' />

        </div>
      </div>
    </div>
  )
}

export default SearchPageCard