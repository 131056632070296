import React from 'react'

interface HeaderTextProps {
  text: String
}

const HeaderText: React.FC<HeaderTextProps> = ({text}) => {
  return (
  <div className='text-[16px] font-bold tracking-normal'>
    {text}
  </div>
  )
}

export default HeaderText