import React from 'react'
import { FaHandHoldingWater } from "react-icons/fa";

interface HomePageCategoryProps {
  active: boolean
}

const HomePageCategory: React.FC<HomePageCategoryProps> = ({ active }) => {
  return (
    <div className={`flex items-center cursor-pointer rounded-xl px-4 py-[10px] ${active ? 'bg-blue text-white' : 'bg-white text-textGray border-darkGray border'}`}>
      <FaHandHoldingWater size={14} />
      <div className='ml-2 text-[14px] tracking-tighter text-nowrap font-[500]'>Ac Service</div>
    </div>
  )
}

export default HomePageCategory 