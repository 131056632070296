import React, { useState } from 'react'
import { IoIosArrowBack } from "react-icons/io";
import SearchInput from '../components/form/SearchInput';
import HeaderText from '../components/text/HeaderText';
import Notification from '../components/ui/Notification';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import HomePageCategory from '../components/ui/HomePageCategory';
import NormalText from '../components/text/NormalText';
import SearchPageCard from '../components/ui/SearchPageCard';
import ModalLayout from '../components/layout/ModalLayout';
import MainBoldText from '../components/text/MainBoldText';
import LocationInput from '../components/form/LocationInput';
import MainButton from '../components/form/MainButton';
import Footer from '../components/ui/Footer';
import { FaStar } from 'react-icons/fa';
import { IoLocationSharp } from 'react-icons/io5';
import PText from '../components/text/PText';
import PSmallGrayText from '../components/text/PSmallGrayText';
import PBlackText from '../components/text/PBlackText';
import DescriptionText from '../components/text/DescriptionText';
import LinkText from '../components/text/LinkText';
import StatusView from '../components/text/StatusView';
interface BookingPageProps {

}

const BookingPage: React.FC<BookingPageProps> = ({ }) => {

  return (
    <div className=' bg-background '>
      <div className='pb-14'>
        <div className={`container`}>
          <div className='flex justify-between items-center py-5'>
            <div className=' bg-darkGray flex justify-center items-center w-10 h-10 rounded-full'>
              <IoIosArrowBack size={20} />
            </div>
            <div>
              <HeaderText text='My Booking' />
            </div>
            <Notification />
          </div>
          <div>
            <SearchInput bg='gray' />
          </div>
          <div className='pt-5'>
            <Swiper
              spaceBetween={10}
              slidesPerView={3}
            >
              <SwiperSlide>
                <HomePageCategory active={true} />
              </SwiperSlide>
              <SwiperSlide>
                <HomePageCategory active={false} />
              </SwiperSlide>
              <SwiperSlide>
                <HomePageCategory active={false} />
              </SwiperSlide>
              <SwiperSlide>
                <HomePageCategory active={false} />
              </SwiperSlide>
              <SwiperSlide>
                <HomePageCategory active={false} />
              </SwiperSlide>
              <SwiperSlide>
                <HomePageCategory active={false} />
              </SwiperSlide>
            </Swiper>
          </div>
          <div className='pt-5 pb-3'>
            <div className='py-3 my-3 border rounded-xl border-darkGray w-full container'>
              <div className='flex'>
                <img src="ac.jpg" alt="ac" className=' w-28 max-w-28 object-cover rounded-xl' />
                <div className='ml-2'>

                  <div>
                    <NormalText text='Lorem ipsum dolor sit amet consectetur, adipisicing elit.' />
                  </div>

                  <div className='flex items-center pt-1'>
                    <IoLocationSharp className='mr-1 text-textGray' />
                    <PSmallGrayText text='ABC Avenue Dhaka' />
                    <div className='mx-2'>
                      <div className='w-1 h-1 bg-textGray rounded-full '></div>
                    </div>
                    <FaStar color='#facc15' size={16} />
                    <div className='text-[12px] ml-1 text-yellow-400 font-semibold'>4.4</div>
                    <div className='text-[12px] ml-1 text-textGray'>(532)</div>
                  </div>

                  <div className='h-[1px] w-full bg-darkGray my-3' />


                  <div className='flex justify-between items-center'>
                    <div><PText text={"Date"}/></div>
                    <div><PSmallGrayText text='Wed, 27 Nov 2023'/></div>
                  </div>
                </div>
              </div>
              <div className='w-full h-[1px] bg-darkGray my-4' />

              <div className='flex justify-between items-center'>
                <div>
                  <StatusView type='completed' />
                </div>
                <div>
                  <MainBoldText text={"$26.72"} />
                </div>
              </div>
            </div>

            <div className='py-3 my-3 border rounded-xl border-darkGray w-full container'>
              <div className='flex'>
                <img src="ac.jpg" alt="ac" className=' w-28 max-w-28 object-cover rounded-xl' />
                <div className='ml-2'>

                  <div>
                    <NormalText text='Lorem ipsum dolor sit amet consectetur, adipisicing elit.' />
                  </div>

                  <div className='flex items-center pt-1'>
                    <IoLocationSharp className='mr-1 text-textGray' />
                    <PSmallGrayText text='ABC Avenue Dhaka' />
                    <div className='mx-2'>
                      <div className='w-1 h-1 bg-textGray rounded-full '></div>
                    </div>
                    <FaStar color='#facc15' size={16} />
                    <div className='text-[12px] ml-1 text-yellow-400 font-semibold'>4.4</div>
                    <div className='text-[12px] ml-1 text-textGray'>(532)</div>
                  </div>

                  <div className='h-[1px] w-full bg-darkGray my-3' />


                  <div className='flex justify-between items-center'>
                    <div><PText text={"Date"}/></div>
                    <div><PSmallGrayText text='Wed, 27 Nov 2023'/></div>
                  </div>
                </div>
              </div>
              <div className='w-full h-[1px] bg-darkGray my-4' />

              <div className='flex justify-between items-center'>
                <div>
                  <StatusView type='canceled' />
                </div>
                <div>
                  <MainBoldText text={"$26.72"} />
                </div>
              </div>
            </div>


            <div className='py-3 my-3 border rounded-xl border-darkGray w-full container'>
              <div className='flex'>
                <img src="ac.jpg" alt="ac" className=' w-28 max-w-28 object-cover rounded-xl' />
                <div className='ml-2'>

                  <div>
                    <NormalText text='Lorem ipsum dolor sit amet consectetur, adipisicing elit.' />
                  </div>

                  <div className='flex items-center pt-1'>
                    <IoLocationSharp className='mr-1 text-textGray' />
                    <PSmallGrayText text='ABC Avenue Dhaka' />
                    <div className='mx-2'>
                      <div className='w-1 h-1 bg-textGray rounded-full '></div>
                    </div>
                    <FaStar color='#facc15' size={16} />
                    <div className='text-[12px] ml-1 text-yellow-400 font-semibold'>4.4</div>
                    <div className='text-[12px] ml-1 text-textGray'>(532)</div>
                  </div>

                  <div className='h-[1px] w-full bg-darkGray my-3' />


                  <div className='flex justify-between items-center'>
                    <div><PText text={"Date"}/></div>
                    <div><PSmallGrayText text='Wed, 27 Nov 2023'/></div>
                  </div>
                </div>
              </div>
              <div className='w-full h-[1px] bg-darkGray my-4' />

              <div className='flex justify-between items-center'>
                <div>
                  <StatusView type='going' />
                </div>
                <div>
                  <MainBoldText text={"$26.72"} />
                </div>
              </div>
            </div>
            
          </div>

          

        </div>

      </div>


      <Footer />
    </div>
  )
}

export default BookingPage