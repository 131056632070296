import React from 'react'
import HeaderText from '../components/text/HeaderText'
import NormalText from '../components/text/NormalText'
import { IoLocationSharp } from 'react-icons/io5'
import PText from '../components/text/PText'
import { FaStar } from 'react-icons/fa'
import PSmallGrayText from '../components/text/PSmallGrayText'
import { LuCalendarDays } from "react-icons/lu";
import { IoMdTime } from "react-icons/io";
import MainButton from '../components/form/MainButton'
import NormalInput from '../components/form/NormalInput'
interface ConfirmPageProps {

}

const ConfirmPage: React.FC<ConfirmPageProps> = ({ }) => {
  return (
    <div className='container pt-5'>
      <div className='text-center py-5'>
        <HeaderText text={"Confirm and Pay"} />
      </div>
      <div className='w-full border rounded-lg border-darkGray container py-3'>
        <div className='flex items-center'>
          <img src="ac.jpg" alt="ac" className=' w-24 h-24 max-w-24 min-w-24 min-h-24 max-h-24 rounded-lg object-cover' />
          <div className='ml-3'>
            <NormalText text='Lorem ipsum dolor sit, amet consectetur adipisicing elit.' />
            <div className='flex items-center pt-3'>
              <LuCalendarDays className='mr-1 text-textGray' />
              <div className=''>
                <PSmallGrayText text={"March 29, 2022"} />
              </div>
            </div>
            <div className='flex items-center pt-2'>
              <IoMdTime className='mr-1 text-textGray' />
              <div className=''>
                <PSmallGrayText text={"10:00 PM - 12:00 PM"} />
              </div>
            </div>
          </div>
        </div>
        <div className='h-[1px] w-full bg-darkGray my-6'>
        </div>
        <PText text={"Price Details"} />
        <div className='pt-3'>
          <div className='flex justify-between items-center'>
            <PText text='Price' />
            <NormalText text='$39.00' />
          </div>
        </div>
        <div className='pt-3'>
          <div className='flex justify-between items-center'>
            <PText text='App Fee' />
            <NormalText text='$2.50' />
          </div>
        </div>
        <div className='pt-3'>
          <div className='flex justify-between items-center'>
            <PText text='Total Price' />
            <NormalText text='$41.50' />
          </div>
        </div>
      </div>
      <div className='py-5'>
        <HeaderText text={"Payment Methid"} />
      </div>
      <div>
        <div className='py-3'>
          <div className='pb-1'>
            <PText text='Card Number' />
          </div>
          <NormalInput placeholder={'Enter card Number'} />
        </div>
        <div className='py-3'>
          <div className='pb-1'>
            <PText text='Card Holder Name' />
          </div>
          <NormalInput placeholder={'Enter Card Holder Name'} />
        </div>
        <div className='py-3'>
          <div className='pb-1'>
            <PText text='Your Email' />
          </div>
          <NormalInput placeholder={'Enter Your Email'} />
        </div>
        <div className='py-3'>
          <div className='pb-1'>
            <PText text='Ex Date' />
          </div>
          <NormalInput placeholder={'MM/YY'} />
        </div>
        <div className='py-3'>
          <div className='pb-1'>
            <PText text='CVV Code' />
          </div>
          <NormalInput placeholder={'CVV'} />
        </div>
      </div>
      <div className='py-5'>
        <MainButton text='Pay Now' />
      </div>
    </div>
  )
}

export default ConfirmPage